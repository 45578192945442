import React from 'react';

import { Helmet } from "react-helmet";
import { useState , useEffect } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL + "/SeoMeta/GetMetaData";


const Helmets = () => {

  const [metaTitle, set_MetaTitle] = useState("");
  const [metaDesc, set_MetaDesc] = useState("");
  const [keywords, set_KeyWords] = useState("");
  const [Canonical, set_Canonical] = useState("");
  const [PageTitle, set_PageTitle] = useState("");
  const [Robots, set_Robots] = useState("");

  const location = useLocation();
  const path = location.pathname;
  const url_slug = path.split("/").slice(-2)[0];
  const formdata = {slug: url_slug};

  localStorage.setItem('PageTitle', JSON.stringify(PageTitle));
  
  function getSeoMeta(){
    axios.post(baseURL, formdata).then((response) => {
      if (response.data != null){
        set_PageTitle(response.data.title);
        set_MetaTitle(response.data.metaTitle);
        set_MetaDesc(response.data.metaDesc);
        set_KeyWords(response.data.keywords);
        set_Canonical(response.data.canonical);
        set_Robots("index, follow")
        if (response.data.canonical ==="https://www.skilloyz.com/thank-you/") {
          set_Robots("noindex, nofollow");
        } else {
          set_Robots("index, follow");
        }
      }else{
        set_PageTitle('Page Not Found');
        set_MetaTitle('Page Not Found | Skilloyz');
        set_MetaDesc('');
        set_KeyWords('');
        set_Canonical('');
        set_Robots("noindex, nofollow");
      }
    });
  };

  useEffect(() => {
    getSeoMeta();
   }, [location]);

  return (
    <>
        <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDesc}/>
            <meta name="keywords" content={keywords}/>
            <link rel="canonical" href={Canonical}/>
            <meta property="og:type" content="website" />
            <meta property="og:url" content={Canonical} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDesc} />
            <meta property="og:image" content="https://www.skilloyz.com/web-tag.png" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={Canonical} />
            <meta property="twitter:title" content={metaTitle} />
            <meta property="twitter:description" content={metaDesc} />
            <meta property="twitter:image" content="https://www.skilloyz.com/web-tag.png" />
            <meta name="robots" content={Robots}/> 
             
        </Helmet> 
    </>
  )
}

export default Helmets